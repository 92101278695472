import { EditorReadyFn, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { getFrameUrl } from './utils/editor';
import keyboardShortCutsApp from './components/keyboardShortCutsApp/editor.controller';
import { AppApi } from './common/types';

const TOKEN = 'token';
let isEditorX: boolean = false;
export const editorReady: EditorReadyFn = async (
  editorSDK: FlowEditorSDK,
  token: string,
  { firstInstall },
  flowAPI: FlowAPI,
) => {
  openSidePanel(editorSDK, flowAPI);
  isEditorX = flowAPI?.environment?.isEditorX;
};
const openSidePanel = async (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => {
  const toolsMenuItemOptions = { title: flowAPI.translations.t('app.name') };
  const toolsPanelOptions = {
    title: flowAPI.translations.t('app.name'),
    width: 582,
    height: flowAPI?.environment?.isEditorX ? 358 : 331,
    url: getFrameUrl(keyboardShortCutsApp.type, 'Settings'),
  };
  await editorSdk.editor.registerToolsPanel(
    '',
    toolsMenuItemOptions,
    toolsPanelOptions,
  );
};
const createPrivateAPI = (): AppApi => ({
  getIsEditorX: () => isEditorX,
});
export const exports = () => ({
  private: createPrivateAPI(),
});
